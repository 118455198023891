body {
  background-color: #000;
  color: #fff;
  font-family: "Coiny", cursive;
}

.App {
  width: 100%;
  height: 100vh;
  position: relative;
}

.socials {
  position: absolute;
  right: 50px;
  bottom: 50px;
  display: flex;
  gap: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .socials {
    flex-direction: column;
    right: 30px;
    bottom: 100px;
  }
}
